import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tagsList: [],
};

export const CustomerTagsSlice = createSlice({
  name: "customerTags",
  initialState,
  reducers: {
    setCustomerTagsList: (state, action) => {
      state.tagsList = action.payload;
    },
  },
});

export const { setCustomerTagsList } = CustomerTagsSlice.actions;
export default CustomerTagsSlice.reducer;
