import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import authReducer from "./features/auth";
import dashboardReducer from "./features/dashboard";
import filterReducer from "./features/filter";
import ordersReducer from "./features/orders";
import couponsReducer from "./features/coupons";
import conversionReducer from "./features/conversion";
import customerInsightsReducer from "./features/insight";
import customerDetailsReducer from "./features/customerDetails";
import promotionalCouponReducer from "./features/promotionalCoupons";
import promotionalWheelReducer from "./features/promotionalWheel";
import promotionalAdvertisementReducer from "./features/promotionalAdvertisement";
import campaignReducer from "./features/campaign";
import PromotionalPointsReducer from "./features/promotionalPoints";
import PromotionalReportsReducer from "./features/reports";
import appReducer from "./features/app";
import restaurantPositionReducer from "./features/restaurantPosition";
import customerTagsReducer from "./features/customerTags";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};
const reducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  filter: filterReducer,
  orders: ordersReducer,
  coupons: couponsReducer,
  conversion: conversionReducer,
  insights: customerInsightsReducer,
  customer: customerDetailsReducer,
  promotionalCoupons: promotionalCouponReducer,
  promotionalWheel: promotionalWheelReducer,
  promotionalAdvertisement: promotionalAdvertisementReducer,
  promotionalPoints: PromotionalPointsReducer,
  campaignData: campaignReducer,
  reports: PromotionalReportsReducer,
  appData: appReducer,
  restaurantPosition: restaurantPositionReducer,
  customerTags: customerTagsReducer,
});
const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
export const RootState = store.getState();

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;
